import React from "react";
import { cover } from "polished";

import {
  FlexGrid,
  FlexCol,
  PrismicImage,
  Box,
  H6,
  Text,
  RichText,
} from "src/components";

import SocialLinks from "./_socialLinks";

const Opener = ({
  openerName,
  openerImage,
  openerLocation,
  openerDescription,
  openerListenOptions,
  openerSocials,
  openerImportance,
}) => (
  <Box mb={[5, 7]} bg="bg.reverse">
    <FlexGrid gutterX={[3, 5]}>
      <FlexCol
        width={openerImportance === "Headliner" ? [1] : [1 / 3]}
        minHeight={openerImportance === "Headliner" ? [320, 420] : undefined}
      >
        {openerImage && (
          <FlexCol position="relative" flex={1}>
            <div
              css={`
                ${cover()};
              `}
            >
              <PrismicImage image={openerImage} style={{ height: "100%" }} />
            </div>
          </FlexCol>
        )}
      </FlexCol>
      <FlexCol
        width={openerImportance === "Headliner" ? [1] : [2 / 3]}
        justifyContent="center"
      >
        <Box
          pt={openerImportance === "Headliner" ? [3, 4, 5] : [0]}
          py={openerImportance === "Headliner" ? [0] : [3, 5, 7]}
          px={openerImportance === "Headliner" ? [3, 4] : 0}
        >
          <H6
            children={
              openerImportance === "Headliner" ? "Featuring" : "Opening Act"
            }
            color="text.reverse"
          />
          <Text fontSize={4} fontWeight={600} color="text.reverse">
            {openerName}
          </Text>
          {(openerSocials || openerListenOptions) && (
            <SocialLinks
              socialNetworks={openerSocials}
              listenOptions={openerListenOptions}
            />
          )}
        </Box>
      </FlexCol>
      {openerDescription && (
        <FlexCol width={1} m={[3, 4]}>
          {openerName && (
            <H6
              children={
                "About " +
                openerName +
                (openerLocation ? " · " + openerLocation : "")
              }
              color="text.reverse"
              mb={3}
            />
          )}
          <article>
            <RichText fontSize={[1, 2]} color="text.reverse">
              {openerDescription}
            </RichText>
          </article>
        </FlexCol>
      )}
    </FlexGrid>
  </Box>
);

export default Opener;
