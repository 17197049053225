import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { FlexRow, Avatar, Box, H6, RichText, Text } from "src/components";

const About = ({ title, bio, bioAuthor, location }) => (
  <article>
    {title && (
      <H6
        children={"About " + title + (location ? " · " + location : "")}
        mb={3}
      />
    )}
    {bio && (
      <RichText fontSize={[1, 2]} color="text.body">
        {bio}
      </RichText>
    )}
    {bioAuthor && (
      <FlexRow justifyContent="flex-start" alignItems="center" mt={3}>
        {bioAuthor.picture && bioAuthor.picture.url && (
          <Avatar image={bioAuthor.picture} />
        )}
        <Box flex={1} ml={bioAuthor.image && (bioAuthor.image.url ? 2 : 0)}>
          {bioAuthor.name && (
            <Text fontSize={[0, 1]} color="text.body">
              Biography by{" "}
              <span
                css={`
                  font-weight: 600;
                `}
              >
                {PrismicRichText.asText(bioAuthor.name)}
              </span>
            </Text>
          )}
        </Box>
      </FlexRow>
    )}
  </article>
);

export default About;
