import React from "react";
import moment from "moment-timezone";

import {
  Box,
  H1,
  H3,
  H6,
  PrismicImage,
  Text,
  SplitHeader,
} from "src/components";

import SocialLinks from "./_socialLinks";

function Intro({
  date,
  virtual,
  title,
  featuring,
  openerImportance,
  image,
  doorTime,
  showTime,
  venue,
  setInvert,
  setInvertLogo,
  socialNetworks,
  listenOptions,
}) {
  return (
    <>
      <SplitHeader
        left={
          <>
            {image && (
              <Box position="relative" bg="bg.reverse">
                <PrismicImage image={image} alt={title && title} sizes="33vw" />
              </Box>
            )}
          </>
        }
        right={
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              height: 100%;
              ${(props) => props.theme.mediaQueries.large} {
                align-items: flex-start;
                text-align: left;
              }
            `}
          >
            {date && (
              <H6
                children={
                  moment(date).format("MMMM D") +
                  (virtual ? " · " + "Online" : venue && " · " + venue)
                }
                color="text.reverse"
              />
            )}
            {title && <H1 children={title} mt={4} color="text.reverse" />}
            {openerImportance === "Headliner" && featuring && (
              <H1 children={"& " + featuring} mt={2} color="text.reverse" />
            )}
            {openerImportance !== "Headliner" && featuring && (
              <H3 children={"With " + featuring} mt={2} color="text.reverse" />
            )}
            {(doorTime || showTime) && (
              <Text
                mt={3}
                fontSize={[0, 1]}
                textAlign={["center", null, null, "initial"]}
                color="text.reverse"
              >
                <span>
                  {doorTime && `Doors @ ${doorTime} MST/MDT`}
                  {showTime && doorTime
                    ? " · Show @ " + showTime + " MST/MDT"
                    : "Show @ " + showTime + " MST/MDT"}
                </span>
              </Text>
            )}
            <div
              css={`
                margin-top: auto;
                padding-top: ${(props) => props.theme.space[4] + "px"};
              `}
            >
              <SocialLinks
                socialNetworks={socialNetworks}
                listenOptions={listenOptions}
              />
            </div>
          </div>
        }
        color="bg.reverse"
        invert={true}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
      />
    </>
  );
}

export default Intro;
