import React from "react";

import { Box, FlexGrid, SectionWrapper, Text } from "src/components";

import About from "./_about";
import Opener from "./_opener";
import Sidebar from "./_sidebar";

const Main = ({
  location,
  ticketLink,
  socialNetworks,
  listenOptions,
  title,
  bio,
  bioAuthor,
  openerName,
  openerImage,
  openerLocation,
  openerDescription,
  openerSocials,
  openerImportance,
  openerListenOptions,
  ticketType,
  ticketState,
  ticketPrice,
  doorPrice,
  presentedBy,
  onSaleDate,
  sponsorTitle,
  sponsors,
}) => (
  <SectionWrapper py={[7, 8]} bg="bg.default">
    <FlexGrid gutterX={[7, 8]}>
      <Box as="section" width={[1, null, 1 / 3]}>
        <Sidebar
          ticketType={ticketType}
          ticketLink={ticketLink}
          socialNetworks={socialNetworks}
          listenOptions={listenOptions}
          ticketPrice={ticketPrice}
          ticketState={ticketState}
          onSaleDate={onSaleDate}
          doorPrice={doorPrice}
          sponsorTitle={sponsorTitle}
          sponsors={sponsors}
        />
      </Box>
      <Box as="section" width={[1, null, 2 / 3]}>
        {presentedBy && (
          <Text children={presentedBy} fontWeight={600} fontSize={3} mb={3} />
        )}
        {openerImage && openerName && (
          <Opener
            openerName={openerName}
            openerImage={openerImage}
            openerLocation={openerLocation}
            openerDescription={openerDescription}
            openerSocials={openerSocials}
            openerListenOptions={openerListenOptions}
            openerImportance={openerImportance}
          />
        )}
        <About
          location={location}
          title={title}
          bio={bio}
          bioAuthor={bioAuthor}
        />
      </Box>
    </FlexGrid>
  </SectionWrapper>
);

export default Main;
