import React from "react";
import { hideVisually } from "polished";
import {
  mdiFacebookBox,
  mdiTwitter,
  mdiInstagram,
  mdiYoutube,
  mdiMusic,
  mdiSpotify,
  mdiSoundcloud,
  mdiBandcamp,
  mdiWeb,
} from "@mdi/js";

import { Box, FlexGrid, Icon, PrismicLink, Text } from "src/components";

const SocialButton = ({ icon, label, link }) => (
  <PrismicLink link={link}>
    <div
      css={`
        width: 56px;
        div {
          transition: transform 250ms ${(props) => props.theme.easing};
        }
        svg {
          transition: opacity 125ms ${(props) => props.theme.easing};
        }
        @media not all and (hover: none) {
          &:hover {
            div {
              transform: scale(0.95);
              transform-origin: center;
            }
            svg {
              opacity: 0.75;
            }
          }
        }
      `}
    >
      <div
        css={`
          position: relative;
          padding-bottom: 100%;
          color: ${(props) => props.theme.colors.text.reverse};
          cursor: pointer;
          transition: transform 500ms ${(props) => props.theme.easing};

          svg {
            transition: opacity 350ms ${(props) => props.theme.easing};
          }
        `}
      >
        <Icon
          path={icon}
          size={1.75}
          css={`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: currentColor;
          `}
        />
        <Text
          children={label}
          css={`
            ${hideVisually()};
          `}
        />
      </div>
    </div>
  </PrismicLink>
);

const SocialLinks = ({ socialNetworks, listenOptions }) => (
  <Box ml={-14}>
    {socialNetworks && (
      <FlexGrid gutterX={[0]} gutterY={[0]}>
        {socialNetworks.map((network, index) => {
          if (network.link && network.link.url) {
            return (
              <SocialButton
                icon={
                  network.social_network === "Facebook"
                    ? mdiFacebookBox
                    : network.social_network === "Twitter"
                    ? mdiTwitter
                    : network.social_network === "Instagram"
                    ? mdiInstagram
                    : mdiWeb
                }
                label={network.social_network}
                link={network.link}
                key={"socialNetwork" + index}
              />
            );
          } else {
            return null;
          }
        })}
        {listenOptions.map((listenOption, index) => {
          if (listenOption.link && listenOption.link.url) {
            return (
              <SocialButton
                icon={
                  listenOption.music_type === "Spotify"
                    ? mdiSpotify
                    : listenOption.music_type === "SoundCloud"
                    ? mdiSoundcloud
                    : listenOption.music_type === "Bandcamp"
                    ? mdiBandcamp
                    : listenOption.music_type === "Apple Music"
                    ? mdiMusic
                    : mdiYoutube
                }
                label={listenOption.music_type}
                link={listenOption.link}
                key={"listenOption" + index}
              />
            );
          } else {
            return null;
          }
        })}
      </FlexGrid>
    )}
  </Box>
);

export default SocialLinks;
