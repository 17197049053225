import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO } from "src/components";

import Intro from "./_components/_intro";
import Main from "./_components/_main";

const ConcertPage = ({ data, setInvert, setInvertLogo, preview }) => {
  let artist = {};
  let supportingArtist = {};
  let concert = {};
  if (!data) {
    data = preview;
  }
  if (data.concert) {
    if (data.concert.dataString) {
      concert = JSON.parse(data.concert.dataString);
    }
    if (concert.performer) {
      //find performer data
      data.artists.edges.forEach((outerArtist) => {
        if (concert.performer.id === outerArtist.node.prismicId) {
          artist = JSON.parse(outerArtist.node.dataString);
        }
      });
    }
    if (concert.opener) {
      //find opener data
      data.artists.edges.forEach((outerArtist) => {
        if (concert.opener.id === outerArtist.node.prismicId) {
          supportingArtist = JSON.parse(outerArtist.node.dataString);
        }
      });
    }
  }

  return (
    <>
      <SEO
        image={artist && artist.image && artist.image.url && artist.image.url}
        imageAlt={
          artist && artist.image && artist.image.alt && artist.image.alt
        }
        title={artist.artist_name && RichText.asText(artist.artist_name)}
        image={artist.image && artist.image.url}
      />
      <Intro
        title={artist.artist_name && RichText.asText(artist.artist_name)}
        featuring={
          supportingArtist.artist_name &&
          RichText.asText(supportingArtist.artist_name)
        }
        openerImportance={concert.opener_importance}
        virtual={concert.virtual_concert}
        date={concert.date}
        location={artist.location}
        image={artist.image && artist.image}
        showTime={concert.show_time}
        doorTime={concert.door_time}
        venue={concert.venue}
        setInvert={setInvert}
        setInvertLogo={setInvertLogo}
        socialNetworks={artist.social_links}
        listenOptions={artist.listen}
      />
      <Main
        location={artist.location}
        title={artist.artist_name && RichText.asText(artist.artist_name)}
        ticketLink={
          concert.ticket_link && concert.ticket_link.url && concert.ticket_link
        }
        ticketState={concert.ticket_state}
        onSaleDate={concert.on_sale_date}
        ticketType={concert.ticket_price_type}
        listenOptions={artist.listen}
        bio={
          artist.bio && RichText.render(artist.bio, linkResolver, serializer)
        }
        bioAuthor={artist.bioAuthor}
        openerName={
          supportingArtist.artist_name &&
          RichText.asText(supportingArtist.artist_name)
        }
        openerImage={supportingArtist.image && supportingArtist.image}
        openerLocation={supportingArtist.location}
        openerDescription={
          supportingArtist.bio &&
          RichText.render(supportingArtist.bio, linkResolver, serializer)
        }
        openerSocials={supportingArtist.social_links}
        openerListenOptions={supportingArtist.listen}
        openerImportance={concert.opener_importance}
        doorPrice={"$" + concert.door_price}
        ticketPrice={
          concert.ticket_price === 0 ? "Free" : "$" + concert.ticket_price
        }
        sponsorTitle={concert.sponsor_heading}
        sponsors={concert.sponsors}
        presentedBy={concert.presented_by}
      />
    </>
  );
};

export default ConcertPage;

export const query = graphql`
  query($id: String!) {
    concert: prismicConcer(id: { eq: $id }) {
      prismicId
      dataString
    }
    artists: allPrismicArtist {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
    authors: allPrismicStaffMember {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
  }
`;
