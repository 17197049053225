import React, { useState, useEffect } from "react";
import moment from "moment-timezone";

import {
  Box,
  Button,
  FlexRow,
  FlexCol,
  H6,
  H3,
  PrismicLink,
  Text,
} from "src/components";

function Sidebar({
  ticketType,
  onSaleDate,
  ticketState,
  ticketPrice,
  ticketLink,
  listenOptions,
  sponsorTitle,
  sponsors,
}) {
  const [spotifyEmbed, setSpotifyEmbed] = useState(undefined);

  useEffect(() => {
    if (listenOptions) {
      listenOptions.forEach((option) => {
        if (option.music_type === "Spotify") {
          if (option.link && option.link.url) {
            setSpotifyEmbed(
              option.link.url
                .split("?")[0]
                .replace("open.spotify.com", "open.spotify.com/embed")
            );
          }
        }
      });
    }
  }, []);

  return (
    <Box>
      {ticketLink && (
        <Box
          mb={5}
          css={`
            border: 1px solid ${(props) => props.theme.colors.bg.alt};
            border-radius: 4px;
            padding: ${(props) => props.theme.space[4] + "px"};
          `}
        >
          <FlexCol mb={4}>
            <H3
              children={
                ticketPrice === "$null"
                  ? "Free show"
                  : ticketType === "Tiered"
                  ? "Starting At"
                  : "Tickets"
              }
            />
            {ticketPrice !== "$null" && (
              <FlexRow mt={4}>
                <FlexCol width={1}>
                  <H6 children={ticketPrice} />
                  <Text children="Online" mt={1} fontSize={1} />
                </FlexCol>
              </FlexRow>
            )}
          </FlexCol>
          {ticketLink && (
            <Button
              as={PrismicLink}
              link={ticketLink}
              children={
                ticketPrice === "$null"
                  ? "RSVP"
                  : ticketState !== "Sold Out"
                  ? ticketPrice === "Free"
                    ? "RSVP"
                    : onSaleDate
                    ? `Buy tickets`
                    : `Buy tickets`
                  : ticketState
              }
              width={1}
              variant="primary"
              disabled={
                ticketState === "Sold Out" ||
                ticketState === "Cancelled" ||
                ticketState === "Postponed"
                  ? true
                  : false
              }
              style={
                ticketState === "Sold Out" ||
                ticketState === "Cancelled" ||
                ticketState === "Postponed"
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
            />
          )}
          {ticketPrice !== "$null" && (
            <Text fontSize={0} mt={2}>
              With the exception of event cancellation, all sales are final.
            </Text>
          )}
        </Box>
      )}
      {sponsors && sponsors.length > 0 && sponsors[0].sponsor_text && (
        <>
          {sponsorTitle && <H6 children={sponsorTitle} mb={2} />}
          {sponsors.map(({ sponsor_logo, sponsor_text }, index) => (
            <FlexCol
              px={4}
              py={3}
              mb={2}
              css={`
                border: 1px solid ${(props) => props.theme.colors.bg.alt};
              `}
              key={"concertSponsor" + index}
            >
              {sponsor_logo && sponsor_logo.url && (
                <Box
                  as="img"
                  src={sponsor_logo.url}
                  alt={sponsor_logo.alt}
                  width="100%"
                  height="auto"
                  maxWidth={128}
                />
              )}
              {sponsor_text && <H6 children={sponsor_text} mt={2} />}
            </FlexCol>
          ))}
        </>
      )}
      <Box display={["none", null, "initial"]}>
        {spotifyEmbed && (
          <iframe
            title="spotify"
            src={spotifyEmbed}
            width="100%"
            height="400"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          />
        )}
      </Box>
    </Box>
  );
}

export default Sidebar;
